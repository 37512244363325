import React from "react";

import Layout from "./layout-main";
import { StoryPage } from "./story-page";
import { FirebaseContext } from "../services";
import "firebase/firestore";

/** @jsx jsx */
import { jsx } from "theme-ui";
import { convertTimestampToUtcDate } from "./dates";
import Routes from "../constants/routes";

const isBrowser = typeof window !== "undefined" && window;

const DynamicStoryPage = ({ location }) => {
  const { db, isInitialized } = React.useContext(FirebaseContext);
  const pageSlug = String(location.pathname)
    .split(Routes.STORIES_PREFIX)[1]
    .replace(/\//g, "");

  const previewId = new URLSearchParams(location.search).get("preview");

  React.useEffect(() => {
    if (!isInitialized) {
      return;
    }

    if (pageSlug || previewId) {
      let query = db.collection("humans");
      if (previewId) {
        query = query.doc(previewId);
      } else if (pageSlug) {
        query = query
          .where("pageSlug", "==", pageSlug)
          .where("isPublished", "==", true)
          .limit(1);
      }
      query
        .get()
        .then(function (result) {
          let data;
          // querySnapshot
          if (result.forEach && typeof result.forEach === "function") {
            result.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots
              // console.log(doc.id, " => ", doc.data());
              // console.log(new Date(doc.data().seconds));
              data = doc.data();
            });
          } else {
            data = result.data();
          }
          if (data && data.password) {
            let password = window.prompt("Please enter the password");
            if (password !== data.password) {
              data = null;
            }
          }
          setFoundHuman(data);
          setIsLoaded(true);
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    } else {
      setIsLoaded(true);
    }
  }, [isInitialized]);

  const [foundHuman, setFoundHuman] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);

  if (!isBrowser) {
    return null;
  }

  return (
    <>
      {foundHuman && isLoaded && foundHuman.variant != "paymentOverdue" && (
        <StoryPage
          location={location}
          imgUrl={foundHuman?.imageUrl || foundHuman.imgUrl}
          fullName={[
            foundHuman.firstName,
            foundHuman.middleName,
            foundHuman.lastName,
          ].join(" ")}
          zoomUrl={foundHuman.zoomSettings?.url}
          zoomPhoneNumber={foundHuman.zoomSettings?.phone}
          zoomMeetingId={foundHuman.zoomSettings?.id}
          zoomMeetingPasscode={foundHuman.zoomSettings?.passcode}
          rsvp={foundHuman.rsvp}
          optInviteChild={foundHuman.optInviteChild}
          customColumnSpacing={foundHuman.customColumnSpacing}
          birthDate={convertTimestampToUtcDate(foundHuman.birthDate)}
          deathDate={convertTimestampToUtcDate(foundHuman.deathDate)}
          birthDeathFooter={foundHuman.birthDeathFooter}
          memorialDate={convertTimestampToUtcDate(foundHuman.memorialDatetime)}
          memorialTitle={foundHuman.memorialTitle || ""}
          memorialTime={foundHuman.memorialTimezones}
          inviteText={foundHuman.inviteText}
          obituary={foundHuman.obituary}
          dropboxUrlAppendix={foundHuman.dropboxUrlAppendix}
          isIndexedBySearch={foundHuman.isIndexedBySearch}
          carouselUrls={foundHuman?.carouselUrls}
          storiesRecordUrl={
            foundHuman.storiesRecordUrl || foundHuman.storiesQuestionUrl
          }
          storiesSubmissionsUrl={
            foundHuman.storiesSubmissionsUrl || foundHuman.storiesAnswersUrl
          }
          recordingEmbedUrl={foundHuman.recordingEmbedUrl}
          recordingEmbedDirectUrl={foundHuman.recordingBlobs}
          dropboxUrl={foundHuman.dropboxUrl}
          isDraft={foundHuman.isDraft}
          variant={foundHuman.variant}
          humanModel={foundHuman}
        ></StoryPage>
      )}
      {foundHuman && isLoaded && foundHuman.variant === "paymentOverdue" && (
        <Layout
          location={location}
          pageTitle="Payment Overdue"
          hideSubscribe={true}
        >
          <section>
            <div className="aw-content">
              <h1>Payment Overdue</h1>
              <p>
                You visited a Memorial Page with overdue payments. The page will
                be automatically republished upon payment.
              </p>
              <p>
                Please reach out to{" "}
                <a href="mailto:contact@afterword.com">contact@afterword.com</a>{" "}
                if you have any questions.
              </p>
            </div>
          </section>
        </Layout>
      )}
      {!foundHuman && isLoaded && (
        <Layout location={location} pageTitle="Not Found">
          <section>
            <div className="aw-content">
              <h1>We couldn't find this person</h1>
              <p>
                Sorry. You visited a page that doesn&#39;t exist or isn't
                accessible to you.
              </p>
            </div>
          </section>
        </Layout>
      )}
      {!isLoaded && (
        // <StoryPage
        //   location={location}
        //   imgUrl=""
        //   fullName=""
        //   customColumnSpacing="1fr 3fr"
        //   inviteText={foundHuman?.inviteText}
        //   obituary=""
        //   storiesRecordUrl=""
        //   storiesSubmissionsUrl=""
        //   recordingEmbedUrl=""
        //   dropboxUrl=""
        // >
        //   <p>loading</p>
        // </StoryPage>
        <div></div>
      )}
    </>
  );
};

export default DynamicStoryPage;
