import React from "react";

/** @jsx jsx */
import { jsx } from "theme-ui";

import { Router } from "@reach/router";
import DynamicStoryPage from "./../../components/dynamic-story";

const ThisPage = ({ location }) => {
  return (
    <Router basepath="/stories">
      <DynamicStoryPage path="/*" />
    </Router>
  );
};

export default ThisPage;
